exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-thanks-tsx": () => import("./../../../src/pages/thanks.tsx" /* webpackChunkName: "component---src-pages-thanks-tsx" */),
  "component---src-projects-template-tsx-content-file-path-workspace-src-projects-golden-valley-ridge-mdx": () => import("./../../../src/projects/template.tsx?__contentFilePath=/workspace/src/projects/golden-valley-ridge.mdx" /* webpackChunkName: "component---src-projects-template-tsx-content-file-path-workspace-src-projects-golden-valley-ridge-mdx" */),
  "component---src-projects-template-tsx-content-file-path-workspace-src-projects-modern-st-paul-mdx": () => import("./../../../src/projects/template.tsx?__contentFilePath=/workspace/src/projects/modern-st-paul.mdx" /* webpackChunkName: "component---src-projects-template-tsx-content-file-path-workspace-src-projects-modern-st-paul-mdx" */),
  "component---src-projects-template-tsx-content-file-path-workspace-src-projects-the-fairmount-mdx": () => import("./../../../src/projects/template.tsx?__contentFilePath=/workspace/src/projects/the-fairmount.mdx" /* webpackChunkName: "component---src-projects-template-tsx-content-file-path-workspace-src-projects-the-fairmount-mdx" */),
  "component---src-projects-template-tsx-content-file-path-workspace-src-projects-the-hamline-mdx": () => import("./../../../src/projects/template.tsx?__contentFilePath=/workspace/src/projects/the-hamline.mdx" /* webpackChunkName: "component---src-projects-template-tsx-content-file-path-workspace-src-projects-the-hamline-mdx" */)
}

